<template>
  <div class="sales-by-sales-channel">
    <EbpCard>
      <!-- Tabs -->
      <b-tabs content-class="mt-3">
        <b-tab
          :title="$t(item)"
          v-for="(item, i) in salesChannelsCategories"
          :key="i"
          @click="type = item"
          :active="type === item"
        >
        </b-tab>
      </b-tabs>

      <b-row class="my-4" align-v="end">
        <!-- Date range -->
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("date-range") }}</p>
          <DatePicker
            type="month"
            v-model="date_range"
            :clearable="false"
            range
            :placeholder="$t('date-range')"
            valueType="format"
            format="MMM-YYYY"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("books") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            v-model="titles"
            multiple
            :async="$isAdmin"
            :options="titleList"
            internal-search
            internal-search-key="label"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("territories") }}</p>
          <AsyncTreeSelect
            v-model="territories"
            :disabled="loading"
            :options="territoryList"
            :async="false"
            multiple
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0" v-if="$isAdmin">
          <p class="mb-1">{{ $t("client") }}</p>
          <AsyncTreeSelect
            v-model="clients"
            :async="$isAdmin"
            action="users/search"
            track-by="id"
            label="name"
            :disabled="loading"
          />
        </b-col>
        <b-col md="2" offset-md="2" class="text-right">
          <ebp-button
            v-text="$t('export-csv')"
            @click="exportCSV"
            :block="isSmallScreen"
            :loading="downloading"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="2">
          <b-checkbox v-model="showFree" @change="updateSeries"
            >{{ $t("show-free-sales") }}
          </b-checkbox>
        </b-col>
        <b-col md="2">
          <b-checkbox v-model="showPaid" @change="updateSeries"
            >{{ $t("show-paid-sales") }}
          </b-checkbox>
        </b-col>
      </b-row>
    </EbpCard>

    <!-- Chart -->
    <EbpCard :loading="loading" v-if="series.length > 0">
      <apexchart
        v-if="series.length > 0"
        width="100%"
        type="bar"
        ref="salesByChannelChart"
        :series="series"
        :options="chartOptions"
        height="300px"
      />
    </EbpCard>

    <!-- Table -->
    <AdvancedDataTable
      :headers="headers"
      :items="records"
      :loading-global="loading"
      :paginate="false"
      :search="false"
      :showLimit="false"
      :showFilters="false"
      :showTotal="false"
      hideActions
      sticky-header="60vh"
    >
      <template
        v-for="header in headers"
        :slot="`cell(${header.key || header})`"
        slot-scope="{ item }"
      >
        <b v-if="item.is_total" :key="header.key || header">{{
          item[header.key || header]
            ? formatNumber(item[header.key || header])
            : "-"
        }}</b>
        <span v-else :key="header.key || header">{{
          item[header.key || header]
            ? formatNumber(item[header.key || header])
            : "-"
        }}</span>
      </template>

      <template #cell(total)="{ item }">
        <b>{{ formatNumber(item.total) }}</b>
      </template>

      <template #cell(channel)="{ item }">
        <b v-if="item.is_total">
          {{ item.channel }}
        </b>
        <template v-else>
          {{ item.channel }}
        </template>
      </template>
    </AdvancedDataTable>
  </div>
</template>

<script>
import salesChannelsCategories from "../../fixtures/sales-channel-categories";
import EbpCard from "../../components/core/ebp-card.vue";
import DatePicker from "../../components/core/DatePicker.vue";
import AdvancedDataTable from "../../components/advanced-data-table";
import { format, subMonths, differenceInMonths, parse } from "date-fns";
import { forEach, mapValues, mapKeys, mergeWith, sum } from "lodash-es";
import addMonths from "date-fns/addMonths";
import FileDownload from "js-file-download";
import AsyncTreeSelect from "@/components/AsyncTreeSelect";

const formatString = "MMM-yyyy";

export default {
  name: "SalesBySalesChannel",
  components: {
    EbpCard,
    DatePicker,
    AdvancedDataTable,
    AsyncTreeSelect
  },
  data() {
    return {
      date_range: [
        format(subMonths(Date.now(), 2), formatString),
        format(Date.now(), formatString)
      ],
      titles: [],
      clients: [],
      territories: [],
      type: "all",
      showPaid: true,
      showFree: true,
      loading: false,
      groupedData: [],
      rawData: [],
      series: [],
      downloading: false
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    updateSeries() {
      this.calculateSeries();
    },
    async exportCSV() {
      this.downloading = true;

      try {
        const res = await this.$store.dispatch("reports/exportCSV", {
          date_range: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          titles: this.titles.join(","),
          territories: this.territories.join(","),
          show_paid: this.showPaid,
          show_free: this.showFree,
          type: this.type,
          user_ids: this.clients.join(",")
        });

        FileDownload(res, "sales-by-channel.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    calculateSeries() {
      let series = {};
      let j = 0;
      forEach(this.groupedData, i => {
        i.forEach(i => {
          const records = mergeWith(
            this.showFree ? JSON.parse(i.data_free_sales).title_sales : {},
            this.showPaid ? JSON.parse(i.data_paid_sales).title_sales : {},
            (objVal, srcVal) => {
              if (typeof objVal === "number" || typeof srcVal === "number") {
                return (objVal || 0) + (srcVal || 0);
              }
              return undefined;
            }
          );

          forEach(records, (scats, bid) => {
            if (this.titles.length && !this.titles.includes(parseInt(bid)))
              return;

            forEach(scats, (trs, scat) => {
              let sales = 0;
              if (typeof trs === "string") return;

              if (!series[scat]) {
                series[scat] = new Array(j + 1).fill(0);
              }

              if (!series[scat][j]) {
                const length = series[scat].length;
                series[scat] = [
                  ...series[scat],
                  ...new Array(j + 1 - length).fill(0)
                ];
              }

              forEach(trs, (s, tr) => {
                if (this.territories.length && !this.territories.includes(tr))
                  return;

                sales += parseInt(s) || 0;
              });

              series[scat][j] += sales;
            });
          });
        });

        j++;
      });

      series = mapValues(series, (sales, name) => {
        return {
          name: this.$t(name),
          data: [
            ...sales,
            ...new Array(Object.values(this.dates).length - sales.length).fill(
              0
            )
          ]
        };
      });

      this.series = Object.values(series);
    },
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("reports/salesBySalesChannel", {
          period: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          type: this.type,
          showFree: this.showFree,
          showPaid: this.showPaid,
          user_ids: this.clients.join(",")
        });

        this.groupedData = res.data;
        this.rawData = res.data_raw;

        this.calculateSeries();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
  computed: {
    titleListIds() {
      return this.titleList.map(t => t.id).join(",");
    },
    headers() {
      return [
        "channel",
        ...this.dates.map(d => ({
          key: d,
          label: format(parse(d, "MMM-yyyy", new Date()), "MMM yyyy")
        })),
        {
          key: "total",
          label: "total-count"
        }
      ];
    },
    records() {
      const records = {};

      forEach(this.groupedData, (i, period) => {
        i.forEach(i => {
          const data = mergeWith(
            this.showFree
              ? (JSON.parse(i.data_free_sales) || {}).title_sales
              : {},
            this.showPaid
              ? (JSON.parse(i.data_paid_sales) || {}).title_sales
              : {},
            (objVal, srcVal) => {
              if (typeof objVal === "number" || typeof srcVal === "number") {
                return (objVal || 0) + (srcVal || 0);
              }
              return undefined;
            }
          );

          forEach(data, (scs, bid) => {
            if (this.titles.length && !this.titles.includes(bid)) return;

            forEach(scs, (trs, sc) => {
              if (typeof trs === "string") return;
              if (!records[sc]) records[sc] = {};

              forEach(trs, (sales, tr) => {
                if (this.territories.length && !this.territories.includes(tr))
                  return;
                if (!records[sc][period]) records[sc][period] = [];
                records[sc][period].push(parseFloat(sales) || 0);
              });
            });
          });
        });
      });

      const rows = [];

      forEach(records, (mos, sc) => {
        const valSum = mapValues(mos, v => sum(v));

        rows.push({
          channel: this.$t(sc),
          ...mapKeys(valSum, (_, k) =>
            format(parse(k, "MMMyyyy", new Date()), "MMM-yyyy")
          ),
          total: sum(Object.values(valSum))
        });
      });

      const totals = {};

      rows.forEach(row => {
        forEach(row, (sales, key) => {
          if (key === "channel") return;
          if (totals[key] == undefined) totals[key] = 0;
          totals[key] += sales;
        });
      });

      rows.push({
        ...totals,
        channel: this.$t("total-count"),
        is_total: true
      });

      return rows;
    },
    dates() {
      const startDate = parse(this.date_range[0], "MMM-yyyy", new Date());
      const endDate = parse(this.date_range[1], "MMM-yyyy", new Date());
      const diffInMonths = Math.abs(differenceInMonths(startDate, endDate)) + 1;

      return new Array(diffInMonths)
        .fill(0)
        .map((_, i) => format(addMonths(startDate, i), "MMM-yyyy"));
    },
    salesChannelsCategories() {
      return ["all", ...salesChannelsCategories];
    },
    titleList() {
      const titles = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(JSON.parse(i.data_paid_sales).title_sales, (d, id) => {
            if (!titles[id])
              titles[id] = {
                id: id,
                label: d.title
              };
          });
        });
      });
      return Object.values(titles);
    },
    territoryList() {
      const territories = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(JSON.parse(i.data_paid_sales).title_sales, d => {
            forEach(d, (v, k) => {
              if (k === "title") return;
              forEach(v, (_, n) => {
                if (!territories[n])
                  territories[n] = {
                    label: n,
                    id: n
                  };
              });
            });
          });
        });
      });
      return Object.values(territories);
    },
    chartOptions() {
      const data = {
        chart: {
          fontFamily: "SF UI Text"
        },
        colors: this.$colors,
        xaxis: {
          categories: this.dates
        },
        yaxis: {
          title: {
            text: this.$t("sales-downloads-text"),
            style: {
              fontSize: "14px",
              fontFamily: "SF UI Text"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          },
          style: "hollow"
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        }
      };
      return data;
    }
  },
  watch: {
    type() {
      this.get();
    },
    date_range() {
      this.get();
    },
    clients() {
      this.get();
    }
  }
};
</script>

<style></style>
